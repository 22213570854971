.loginForm {
  font-size: 12px;
  a {
    color: black;
    text-decoration: none;
  }
}

.franceConnect {
  display: block;
  margin: 20px 0 0;
  cursor: pointer;

  img {
    width: 100%;
  }
}
