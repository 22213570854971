$background: #cada9a;
$background-column: #98b674;
$gold-pastel: #FFECB2;
$gold: #e9c20b;
$brownie: #D6A351;
$dark-gold: #87810f;
$kermit-light: #b0e30b;
$kermit: #55cc00;
$emerald-light: #1ae30b;
$emerald: #0bd93a;
$emerald-dark: #0B8468;
$kermit-transparent: rgba(120,200,50,0.8);
$kermit-very-transparent: rgba(50,120, 0,0.4);
$gray-light: #ddd;
$gray: #bbb;
$gray-dark: #999;

$logoFont: 'Lobster', cursive;
$titleFont: 'Pangolin', sans-serif;
